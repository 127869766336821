$(() => {

    if (!window.location.hash.includes("/Client2Login")) {
        router.navigate("/Client2Login", true);
    }

    // load/clear the layout for this view
    layoutManager.loadLayout("/Client2/Home/Login");

    // toggle bootstrap popover on all elements
    ($('[data-toggle="popover"]') as any).popover({
        placement: 'top',
        trigger: 'hover'
    });

    // disable caps-lock warning in IE and Edge for password fields
    (document as any).msCapsLockWarningOff = true;

    // hide the splash elements when a click is detected within an input in the splash container
    $("input").one("click", () => {
        $("#splashContainer").addClass('col-md-offset-3');
        $('.splash-element').hide();
        $("footer").hide();
    });

    $("#frmSwitchTrackLogin").on("submit",
        (e: any) => {
            e.preventDefault();
            submitForm(e);
        });
});

var recaptchaLoaded = () => {
    var siteKey: any = $('#RecaptchaSiteKey').val();

    grecaptcha.render('recaptcha-container',
        {
            sitekey: siteKey,
            size:"invisible",
            callback(token: any) {
                $("#loginSwitchTrackBtn").append($('<input>').attr('name', 'RecaptchaResponse').attr('type', 'hidden').attr('id', 'au-loginSwitchTrackBtnRecaptcha').val(token));
                submit();
            }
        });
};

//meant to be called post validation an after recaptcha has been called
function submit() {
    let form: any = $('#frmSwitchTrackLogin');
    let type = form.attr("method");
    let url = form.attr("action");
    let inData = form.serialize();

    $.ajax({
        type: type,
        global: true,
        headers: { 'x-allow-json': 'true' },
        url: url,
        data: inData,
        success: handlePartialOrFull,
        error: (xhr: any, ajaxOptions: any, thrownError: any) => {
            handlePartialOrFull(xhr, ajaxOptions, thrownError);
        }
    });
}

function submitForm(e: any) {
    var form: any = $('#frmSwitchTrackLogin');

    form.validate();

    // ensure the switch track code is required, since it is not enforced on the view model
    let switchTrackCode: any = $("#SwitchTrackCode");
    switchTrackCode.rules("add", {
        required: true,
        messages: { required: $("#WebResources_ACTIVATION_CODE_REQUIRED").val() }
    });

    if (form.valid()) {
        if ("grecaptcha" in window) {
            grecaptcha.execute();
        }
        else {
            submit();
        }


    }
};